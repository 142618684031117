import React, { useState, useRef } from "react"
import "../static/style/pageStyle/aws-foundation.scss"
import BannerHeader from "../components/bannerHeader"
import Loadable from "@loadable/component"
import { Link } from "gatsby"
import UnderlineButton from "../components/underlineButton"
import banner from "../static/images/aws-foundation/aws-foundation-banner.png"
import bannerMobile from "../static/images/aws-foundation/aws-foundation-banner-mobile.png"
import Underline from "../components/underline"
import genAiCompImage from "../static/images/aws-foundation/genAi-comp.png"
import dataAnalyticsImage from "../static/images/aws-foundation/data&analytics.png"
import devopsServiceImage from "../static/images/aws-foundation/devops-service.svg"
import mlServiceImage from "../static/images/aws-foundation/ml-service.png"
import offeringIcon1 from '../static/images/aws-foundation/offering-icon-1.png';
import offeringIcon2 from '../static/images/aws-foundation/offering-icon-2.png';
import offeringIcon3 from '../static/images/aws-foundation/offering-icon-3.png';
import offeringIcon4 from '../static/images/aws-foundation/offering-icon-4.png';
import Parser from "html-react-parser"

import BlackBtn from "../components/black-btn"

const AwsConsultingPartner = props => {

    const [isNavBarOpen, setIsNavBarOpen] = useState(true);
    const [partneringIndex, setPartneringIndex] = useState(0);
    const [activeTab, setActiveTab] = useState(0)
    const [mobileActiveTab, setMobileActiveTab] = useState(false)
    const [activeIndexforImage, setActiveIndexforImage] = useState(0);
    let count = 1;

    const [dActiveTab, setDActiveTab] = useState(true)
    const [mdActiveTab, setMDActiveTab] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const handleIsOpenModal = (isModalOpen) => {
        setIsModalOpen(!isModalOpen);
        setIsOpen(!isOpen)
    }
    const handleClick = header => {
        setActiveTab(header)
        setDActiveTab(!dActiveTab)
        if (activeTab === header) {
            setDActiveTab(!dActiveTab)
        }
        // console.log(activeTab, "as")
    }
    const handleClickMobile = header => {
        // console.log(header, "header")
        setMobileActiveTab(header)
        setMDActiveTab(!mdActiveTab)
        if (mobileActiveTab === header) {
            setMDActiveTab(!mdActiveTab)
        }
    }

    const changePartneringData = (index) => {
        setPartneringIndex(index);
    }

    const delExpSection = [
        {
            image: genAiCompImage,
            heading: "GenAI Competency",
            paragraph: "Lead in  innovation with expertise in GenAI, creating impactful, next-gen solutions"
        },
        {
            image: dataAnalyticsImage,
            heading: "AWS Partner - Data and Analytics Software",
            paragraph: "Unlock the power of data with advanced analytics and data management solutions"
        },
        {
            image: devopsServiceImage,
            heading: "AWS Partner - DevOps Software",
            paragraph: "Streamline your operations with efficient DevOps practices, ensuring agility and scalability"
        },
        {

            image: mlServiceImage,
            heading: "AWS Partner - Machine Learning Software",
            paragraph: "Accelerate AI capabilities with specialized machine learning solutions tailored to your industry"
        }


    ]

    const partneringData = [
        {
            header: "Strategy & Consulting",
            paragraph: "We help our customers in setting up the data and GenAI roadmap in line with the strategic business goals. Our consulting expertise ensures that each organization’s data foundation is strategically designed to support the future needs of the organization."
        },
        {
            header: "Setting up the right data foundation",
            paragraph: "We build secure, reliable, and optimized data architectures that streamline data ingestion, processing, and transformation—ensuring data is GenAI-ready and positioned to drive meaningful insights."
        },
        {
            header: "Data Security and Scalability",
            paragraph: "With AWS's robust infrastructure, we implement robust and industry-standard data security and protection frameworks, giving customers the confidence to scale GenAI solutions securely and efficiently."
        },
        {
            header: "Tailored Solution",
            paragraph: "Our tailored GenAI solutions are designed with specific industry challenges in mind - helping our customers unlock real and targeted business value."
        },
    ]

    const geniaiAdoptions = [
        {
            header: "Unlock Your Business Potential with Custom Data Solutions",
            paragraph: "Unlock your business potential by engaging with LUMIQ—an AWS Data & Analytics Competency Partner, Data-Driven Everything (D2E) specialist, and Data Foundation Enabled collaborator. LUMIQ brings deep expertise in AWS databases, analytics, and AI/ML services, crafting custom solutions that turn your data into actionable insights."
        },
        {
            header: "Accelerate Time-to-Value with Pre-Built Solutions",
            paragraph: "Working with LUMIQ can significantly accelerate your time-to-value. Leveraging pre-built solutions, accelerators, processes, and best practices, LUMIQ enables you to deploy data solutions swiftly and efficiently."
        },
        {
            header: "Ongoing Support and Optimization Services",
            paragraph: "Beyond strategizing, designing, and building your initial solution, LUMIQ provides ongoing support and optimization services. We assist in continuously monitoring, maintaining, and enhancing your data infrastructure and large language model (LLM) operations, ensuring sustained success for your business."
        },
    ]

    const lumiqOfferingCard = [
        {
            icon: offeringIcon1,
            header: "Data Strategy and Consulting",
            paragraph: "Building a foundation for data excellence with assessments in data maturity, migration, modernization, & architecture design."
        },
        {
            icon: offeringIcon2,
            header: "Advanced Data Products",
            paragraph: "Leveraging data platforms, IP, and accelerators to supercharge your GenAI journey with scalable, ready-to-deploy solutions."
        },
        {
            icon: offeringIcon3,
            header: "Data Governance and Management",
            paragraph: "Ensuring top-tier data quality, privacy, platform monitoring, and governance consulting to keep your GenAI initiatives compliant and secure."
        },
        {
            icon: offeringIcon4,
            header: "Data Analytics and AI",
            paragraph: "Delivering expertise in generative AI, model development, fine-tuning, deployment, LLMOps, and model explainability for robust, transparent AI solutions."
        },
    ]
    return (
        <div
            className={`lumiq-aws ${isNavBarOpen ? "" : "no-scroll"}`}
        >
            <div className="page-content">

                {/* banner section  */}
                <div className="banner-section">
                    <div className="page-wrapper">
                        <div className="main-container">
                            <div className="heading">
                                <BannerHeader headingType={"bannerTitle"} header={"Unlock the Future of GenAI with AWS & LUMIQ"} class="heading-md" line={"two-line"} />
                            </div>
                            <div className="para">
                                <p>With 8+ years of experience in building cutting-edge solutions on AWS, LUMIQ enables financial services organizations to turn their data into strategic assets, fueling GenAI innovation.<br /><br />As one of the select launch partners in AWS’s Data Foundations for GenAI initiative, we’re deepening our commitment to merging AWS’s powerful cloud infrastructure with our deep expertise in setting up robust data foundations. Together, we’re helping our clients unlock actionable insights and make impactful and data-driven  decisions that deliver real business value.</p>
                            </div>
                            <div className="btn-area">
                                <a href={"https://aws.amazon.com/data/partners/?aws-for-data-partners-cards.sort-by=item.additionalFields.partnerName&aws-for-data-partners-cards.sort-order=asc&awsf.aws-for-data-partners-region=*all&aws-marketplace-cards.sort-by=item.additionalFields.sortOrder&aws-marketplace-cards.sort-order=asc&awsf.aws-marketplace-aws-marketplace-aim=*all&awsf.aws-marketplace-aim=aws-marketplace-aim%23gen-ai-software-competency-partner&aws-for-data-partners-cards.q=lumiq&aws-for-data-partners-cards.q_operator=AND"} target="_blank"><BlackBtn text={"Know More"} /></a>

                            </div>
                        </div>
                    </div>
                </div>

                {/* below image  */}
                <div className="image-section">
                    <div className="page-wrapper">
                        <img src={banner} />
                    </div>
                </div>
                <div className="image-section-mobile">
                    <div className="page-wrapper">
                        <img src={bannerMobile} />
                    </div>
                </div>
                <Underline />

                <div className="del-exp">
                    <div className="page-wrapper">
                        <div className="main-container">
                            <div className="left-content">
                                <h2>8+ Years of
                                    Delivering Excellence
                                    with AWS Expertise
                                </h2>
                                <p>For over 8 years, LUMIQ's  strategic partnership with AWS has fueled groundbreaking innovation, delivering value to our customers. Our solutions are crafted to meet the unique needs of every industry and the following AWS competencies are a testament to our capabilities in this spcae.</p>
                            </div>
                            <div className="right-image-click">
                                <div className="images-list">
                                    {delExpSection.map((item, index) => (
                                        <div
                                            className={`child-image ${activeIndexforImage === index ? 'active' : 'inactive'}`}
                                            onClick={() => setActiveIndexforImage(index)} >
                                            <img src={item.image} alt={"imgAlt"} />
                                        </div>))}
                                </div>
                                <div className="image-content">
                                    <h3>{delExpSection[activeIndexforImage].heading}</h3>
                                    <p>{delExpSection[activeIndexforImage].paragraph}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Underline />
                <div className="partnering-foundation">
                    <div className="page-wrapper">
                        <div className="main-container">
                            <h2>Partnering the Data Foundations for GenAI initiative</h2>
                            <p className="para">The Data Foundations for GenAI initiative is all about setting up the right data groundwork to unlock the full potential of the GenAI technology. Here's how we make it happen:</p>
                            <div className="content">
                                <div className="headers">
                                    {
                                        partneringData.map((item, index) => (
                                            <div className={partneringIndex === index ? "active-heading" : "heading"} onClick={() => { changePartneringData(index) }}>
                                                <p>0{index+1}&nbsp; &nbsp;{item.header}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="explained-para">
                                    <p>{partneringData[partneringIndex].paragraph}</p>
                                </div>
                            </div>
                            <div className="content-mobile">
                                <div className="headers">
                                    {
                                        partneringData.map((item, index) => (
                                            <div className={partneringIndex === index ? "active-heading" : "heading"} onClick={() => { changePartneringData(index) }}>
                                                <h3>0{index + 1}&nbsp; &nbsp; <span>{item.header}</span></h3>
                                                {partneringIndex === index && <p className="para-content">{item.paragraph}</p>}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Underline />
                <div className="build-holistic">
                    <div className="page-wrapper">
                        <div className="content">
                            <div className="aws-cons-left-details">

                                <h2>Build a Holistic Data Ecosystem Leveraging LUMIQ and AWS</h2>
                                <p>As  GenAI technology progresses, organizations are keen to leverage it for creating innovative products, services, and experiences. However, fully capitalizing on it requires a strong data foundation—one that can ingest diverse data sources and support ongoing model refinement and customization.<br /><br />
                                By partnering with LUMIQ, you can build a robust data foundation on AWS that transforms your GenAI ambitions from prototype to production at scale. Whether it's crafting hyper-personalized content, automating complex tasks, or reinventing customer experiences, LUMIQ equips you with the tools and expertise to accelerate your journey swiftly and efficiently.</p>
                            </div>
                            <div className="aws-cons-right-details">
                                <img src={"https://webbk.lumiq.ai/uploads/aws_1f350205d5.png"} alt={"imgAlt"} />
                            </div>
                        </div>
                    </div>
                </div>

                <Underline />
                <div className="genai-adoption">
                    <div className="page-wrapper">
                        <div className="main-container">
                            <h2>Why Work with Us—Your Trusted AWS Data Foundation Partner</h2>
                            <div className="content">
                                {geniaiAdoptions.map((content, index) => (
                                    <>
                                        <div className="content-details">
                                            <h3 className="benefit-heading">
                                                {Parser(content.header)}
                                            </h3>
                                            <div className="fs-link">
                                                <p>{content.paragraph}</p>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <Underline />
                {/* <div className="lumiq-offering">
                    <div className="page-wrapper">
                        <div className="main-container">
                            <h2>LUMIQ's Offerings That Empower GenAI Success</h2>
                            <div className="cards-container">
                                {
                                    lumiqOfferingCard.map((item, index) => (
                                        <div className="card">
                                            <div className="icon">
                                                <img src={item.icon} />
                                            </div>
                                            <h3>{item.header}</h3>
                                            <p>{item.paragraph}</p>

                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <Underline /> */}
                <div className="empowering-financial">
                    <div className="page-wrapper">
                        <div className="main-container">
                            <h2>Empowering Financial Services with Scalable, Secure GenAI</h2>
                            <p>Are You Ready to Empower Every Step of Your GenAI Journey?</p>
                            <div className='btn-area'>
                                <a href={"/get-started"}><BlackBtn text={"Contact Us"} /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <Underline />
            </div>
        </div >
    )
}

export default AwsConsultingPartner
